import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

export default function DiscoverButton({ image, title, text = '', link }) {
  return (
    <Card>
      <CardActionArea component={Link} to={link}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
        {image && <CardMedia component="img" image={image} alt={title} width="600" height="400" />}
      </CardActionArea>
    </Card>
  );
}
