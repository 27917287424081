import React from 'react';

import { Box, Divider, Typography, Button, Stack, CardContent, Card } from '@mui/material';

import PageContentContainer from 'components/PageContentContainer';
import { Link } from 'react-router-dom';
import DiscoverButton from 'pages/DiscoverPage/DiscoverButton';

const HomePage = () => {
  return (
    <PageContentContainer heading="homepage" breadcrumb="homepage">
      <Box>
        <Box
          id="header"
          sx={{
            py: '40px',
          }}
        >
          <Typography variant="h1" component="h1">
            ZIKI.FM
          </Typography>
          <Typography variant="subtitle1">
            Stay up to date with all your favourite artists. Get news, concerts, merch, setlists and
            videos aggregated all into one place.
          </Typography>
        </Box>
        <Divider sx={{ width: '100%', height: 2, backgroundColor: 'primary.main' }} />
        <Card sx={{ width: { sm: '50%' }, my: 4 }} variant="outlined">
          <CardContent>
            <Typography gutterBottom>
              Create an account now and start adding your favourite artists to Your Music page and
              stay up to date with your favourite music.
            </Typography>
            <Button variant="outlined" sx={{ mt: 2 }} component={Link} to="/auth/create-account">
              Get started
            </Button>
          </CardContent>
        </Card>
        <Box
          sx={{
            my: 5,
            display: 'grid',
            gridTemplateColumns: { sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
            gap: 2,
          }}
        >
          <DiscoverButton title="Artists" link="/artists" image="/srv/images/zikifm_artists" />
          <DiscoverButton title="Concerts" link="/concerts" image="/srv/images/zikifm_concerts" />
          <DiscoverButton title="Merch" link="/merch" image="/srv/images/zikifm_merch" />
          <DiscoverButton title="News" link="/news" image="/srv/images/zikifm_news" />
        </Box>
      </Box>
    </PageContentContainer>
  );
};

export default HomePage;
