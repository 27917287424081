import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Link as MUILink, Box } from '@mui/material/';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const classes = {
  heading: {
    position: 'relative',
    width: '100%',
  },
  headingContainer: {
    marginBottom: '40px',
  },
  headingImage: (theme) => ({
    content: "''",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '55px',
    position: 'absolute',
    bottom: -57,
    [theme.breakpoints.down('lg')]: {
      height: '35px',
      bottom: -37,
    },
  }),
  headingBackground: (theme) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#1f1f1f',
    height: '100%',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  homepageBackground: {
    zIndex: -1,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  homepageHeading: {
    lineHeight: 1,
    fontSize: '4rem',
  },
  homepageSubheading: (theme) => ({
    [theme.breakpoints.only('md')]: {
      fontSize: '3.66vw',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '7.5vw',
    },
  }),
  loading: {
    height: '64px',
  },
};

export default function PageHeading({ heading, artist, artistId, breadcrumb }) {
  if (!heading) {
    return <Box sx={classes.loading} />;
  }

  const isHomepage = breadcrumb === 'homepage';

  // TODO: THIS AIN'T GOOD DUDE
  const getBreadcrumbs = () => {
    const homepageCrumb = (
      <MUILink key="crumb-homepage" component={Link} to="/">
        Home
      </MUILink>
    );

    switch (breadcrumb) {
      case 'homepage':
        return [];
      case 'search':
        return [homepageCrumb, <Typography key="crumb-search">Search</Typography>];
      case 'discover':
        return [
          homepageCrumb,
          <MUILink key="crumb-discover" component={Link} to="/discover/">
            Discover
          </MUILink>,
          <Typography key="crumb-discover-heading">{heading}</Typography>,
        ];
      case 'artist':
        return [
          homepageCrumb,
          <MUILink key="crumb-artists" component={Link} to="/artists/">
            Artists
          </MUILink>,
        ];
      case 'user':
        return [homepageCrumb, <Typography key="crumb-users">Users</Typography>];
      case 'admin':
        return [
          homepageCrumb,
          <MUILink key="crumb-admin" component={Link} to="/admin/">
            Admin
          </MUILink>,
        ];
      case 'concert':
        if (artist && artistId) {
          return [
            homepageCrumb,
            <MUILink key="crumb-concerts" component={Link} to="/concerts">
              Concerts
            </MUILink>,
            <MUILink key="crumb-artist" component={Link} to={`/artist/${artistId}`}>
              {artist}
            </MUILink>,
          ];
        }
        return [
          homepageCrumb,
          <Typography key="crumb-concert">Concert</Typography>,
          <Typography key="crumb-heading">{heading}</Typography>,
        ];
      case 'support':
        return [
          homepageCrumb,
          <MUILink key="crumb-support" component={Link} to="/support">
            Support
          </MUILink>,
          <Typography key="default2">{heading || ''}</Typography>,
        ];
      case 'venues':
        return [
          homepageCrumb,
          <Typography key="venues">Venues</Typography>,
          <Typography key="default2">{heading || ''}</Typography>,
        ];
      case 'blog':
        return [
          homepageCrumb,
          <MUILink key="crumb-blog" component={Link} to="/blog">
            Blog
          </MUILink>,
          <Typography key="crumb-heading">{heading}</Typography>,
        ];
      default:
        return [homepageCrumb, <Typography key="default2">{heading || ''}</Typography>];
    }
  };

  return (
    <Box sx={classes.heading}>
      {/* <div
        aria-hidden="true"
        sx={isHomepage ? classes.homepageBackground : classes.headingBackground}
      >
        {!isHomepage && (
          <div style={{ backgroundImage: `url(${image})` }} sx={classes.headingImage} />
        )}
      </div> */}
      <Box sx={classes.headingContainer}>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          {getBreadcrumbs()}
        </Breadcrumbs>
        {isHomepage ? null : (
          <Typography variant="h3" component="h1">
            {heading}
          </Typography>
        )}
      </Box>
      {/* {!isHomepage && image && <div style={{ backgroundImage: `url(${image})`, }} sx={classes.headingImage} />} */}
    </Box>
  );
}
